<template>
  <div>
    <div class="wrapper">
      <div class="header-block">
        <partnerslogo />
        <div class="phone">
          <svg
            class="phone-icon"
            width="46"
            height="46"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="46" height="46" rx="23" fill="white" />
            <path
              d="M15.4941 21.7408C17.5844 26.2936 21.3039 29.9132 25.9283 31.8755L26.6695 32.2058C28.3655 32.9614 30.3591 32.3874 31.3935 30.8456L32.3622 29.4017C32.6772 28.9323 32.5813 28.3 32.1413 27.9451L28.8561 25.2947C28.3736 24.9055 27.664 24.9961 27.2949 25.4942L26.2786 26.8656C23.6708 25.5792 21.5536 23.462 20.2672 20.8542L21.6385 19.8379C22.1366 19.4688 22.2273 18.7592 21.838 18.2767L19.1876 14.9914C18.8327 14.5515 18.2006 14.4556 17.7312 14.7703L16.2774 15.7451C14.726 16.7854 14.1551 18.7955 14.9281 20.4959L15.4933 21.739L15.4941 21.7408Z"
              fill="#00C259"
            />
          </svg>
          <div>
            <p class="phone-disc">Телефон для справки:</p>
            <a href="tel:+375297456442" class="phone-number"
              >+375 29 <span>745-64-42</span></a
            >
          </div>
        </div>

        <span class="header"
          ><span>20</span> банков готовы одобрить <br />кредит по сниженной
          ставке <br />от <span>10,5% </span>годовых</span
        >
      </div>
      <div class="statistics">
        <p>
          10315
          <span
            >благодарных<br />
            клиента</span
          >
        </p>
        <p>92,7% <span>одобрения</span></p>
        <p>
          8
          <span
            >лет на рынке<br />
            банковских услуг</span
          >
        </p>
        <p>
          От
          <span>500 до 300 000 BYN</span>
        </p>
        <p>
          До
          <span>7 лет</span>
        </p>
        <p>
          Без
          <span>залога и поручителей</span>
        </p>
      </div>
      <div class="form">
        <p>Куда отправить расчет условий получения выгодного кредита?</p>
        <p v-if="error" class="error">Неверный формат номера телефона!</p>
        <p v-if="success" class="success">
          Ваша заявка успешно отправлена, в ближайшее время с вами свяжется наш
          менеджер
        </p>
        <p v-if="codeError" class="error">Неверный код мобильного оператора!</p>
        <form>
          <input
            class="input"
            type="text"
            v-model="form.phone"
            v-maska="'+375(##)###-##-##'"
            placeholder="+375(__)___-__-__"
            v-bind:style="{ borderColor: color }"
          />
          <button
            @click="
              phoneNumberValidation(form.phone) ? sendForm() : makeError()
            "
            class="button"
            type="submit"
          >
            Получить расчет
          </button>
        </form>
        <p class="remark">
          Нажимая "Получить расчет" вы соглашаетесь с правилами обработки
          персональных данных и подтверждаете, что ознакомились с политикой
          конфеденциальности
        </p>
      </div>
    </div>
    <div class="statistics-bottom">
      <p>
        10315
        <span>благодарных клиента</span>
      </p>
      <p>92,7% <span>одобрения</span></p>
      <p>
        8
        <span
          >лет на рынке<br />
          банковских услуг</span
        >
      </p>
      <p>
        От
        <span
          >500 до<br />
          300 000 BYN</span
        >
      </p>
      <p>
        До
        <span>7 лет</span>
      </p>
      <p>
        Без
        <span>залога и поручителей</span>
      </p>
    </div>
    <disclamer siteName="CreditCentr.by" />
  </div>
</template>

<script>
import disclamer from "@/components/quiz/disclamer.vue";
import partnerslogo from "@/components/banki/partnerslogo.vue";
import { maska } from "maska";
import Basic from "../../../api/Basic";

export default {
  directives: { maska },
  name: "credit-centr",
  data() {
    return {
      form: {
        phone: "",
        utm: {
          utm_campaign: "",
          utm_content: "",
          utm_medium: "",
          utm_source: "",
          utm_term: "",
        },
      },
      color: "",
      codeError: "",
      error: "",
      success: false,
    };
  },
  components: {
    disclamer,
    partnerslogo,
  },

  mounted() {
    let uri = window.location.href.split("?");
    if (uri.length === 2) {
      let vars = uri[1].split("&");
      let getVars = {};
      let tmp = "";
      vars.forEach(function (v) {
        tmp = v.split("=");
        if (tmp.length === 2) getVars[tmp[0]] = tmp[1];
      });

      this.form.utm.utm_campaign = getVars.utm_campaign;
      this.form.utm.utm_content = getVars.utm_content;
      this.form.utm.utm_medium = getVars.utm_medium;
      this.form.utm.utm_source = getVars.utm_source;
      this.form.utm.utm_term = getVars.utm_term;
      this.utms = uri[1];
    }
  },

  methods: {
    sendForm() {
      if (this.form.phone.length === 17) {
        let data = this.form;
        data.roistat = window.roistat.visit;
        Basic.sendLead(data, 'credit-centr.by').then(function () {
          window.location.href = "/thank-you";
          this.success = true;
          this.codeError = false;
          this.error = false;
          this.color = "";
        });
      } else {
        this.color = "red";
      }
    },


    phoneNumberValidation(phone) {
      if (phone.length === 17) {
        if (phone.includes("(29)")) {
          return true;
        } else if (phone.includes("(33)")) {
          return true;
        } else if (phone.includes("(44)")) {
          return true;
        } else if (phone.includes("(25)")) {
          return true;
        } else if (phone.includes("(17)")) {
          return true;
        } else this.codeError = true;
        this.error = false;
        this.color = "red";
        return false;
      } else this.error = true;
      this.codeError = false;
      this.color = "red";
      return false;
    },

    makeError() {
      this.success = false;
      this.color = "red";
    },
  },
};
</script>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700;900&display=swap");

* {
  font-family: "Inter", sans-serif;
}

.wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;

  padding: 40px 75px 50px 75px;

  background-color: #fff;
  background-image: url(../../assets/creditcentr.jpg);
  background-size: cover;
  background-position: top;
}

.header {
  margin-top: 30px;
  display: block;

  font-size: 46px;
  font-weight: 900;
  color: #000;
  line-height: 55.67px;

  text-shadow: 0px 0px 24px rgba(225, 225, 225, 0.7);
}

.header span {
  color: rgba(0, 194, 89, 1);
  font-size: 1.2em;
  line-height: 1.2em;
}

.phone {
  display: flex;
  align-items: center;
  text-decoration: none;
  position: absolute;
  top: 40px;
  right: 75px;
}

.phone-icon {
  margin-right: 20px;
}

.phone-disc {
  margin-block: 0;
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 14.63px;
  color: rgb(80, 80, 80);
}

.phone-number {
  text-decoration: none;
  margin-block: 0;
  font-size: 24px;
  font-weight: 400;
  line-height: 29.26px;
  color: #000;
}

.phone-number span {
  font-weight: 800;
}

.statistics {
  margin: 15px 0;
  display: flex;
  flex-wrap: wrap;
  padding: 15px 0;
  justify-content: space-between;
  align-items: center;
  width: 646px;
  background-color: #fff;
  border: 1px solid #ececec;
  border-radius: 10px;
}

.statistics p {
  padding: 0 15px;
  width: 28%;
  margin-block: 0;
  display: flex;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  color: rgba(0, 194, 89, 1);
  font-weight: 900;
  font-size: 28px;
  line-height: 34.13px;
}

.statistics p:nth-child(2),
.statistics p:nth-child(5) {
  border-left: 1px solid rgba(236, 236, 236, 1);
  border-right: 1px solid rgba(236, 236, 236, 1);
}

.statistics p:nth-child(1),
.statistics p:nth-child(2),
.statistics p:nth-child(3) {
  margin-bottom: 15px;
}

.statistics p span {
  margin-left: 12px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 400;
  color: rgba(57, 57, 57, 1);
}

.form {
  box-sizing: border-box;
  margin: 15px 0;
  padding: 15px 15px;
  justify-content: space-between;
  align-items: center;
  width: 646px;
  background-color: #fff;
  border: 1px solid #ececec;
  border-radius: 10px;

  .remark {
    margin-top: 20px;
    font-size: 12px;
    color: rgb(99, 99, 99);
    margin-block: 0;
    margin-top: 20px;
  }

  .error {
    color: red;
    margin-block: 0;
    font-size: 12px;
  }

  .success {
    color: rgba(0, 194, 89, 1);
    margin-block: 0;
    font-size: 12px;
  }

  p {
    margin-block: 0;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
  }

  form {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .input {
    display: block;
    box-sizing: border-box;
    padding: 0 10px;
    width: 45%;
    height: 40px;
    background-color: transparent;
    border: 1px solid #ececec;
    border-radius: 10px;
  }
}

.button {
  display: block;
  width: 45%;
  height: 40px;

  font-size: 18px;
  font-weight: 700;
  color: rgb(255, 255, 255);

  border: none;
  background: linear-gradient(
      180deg,
      rgba(132, 235, 155, 0.6) 5.48%,
      rgba(30, 184, 64, 0.6) 96.58%
    ),
    #1eb940;
  box-shadow: 0px 22px 25px -15px rgba(0, 194, 89, 0.4);
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.button:hover,
.button:active {
  transform: scale(105%);
  background: linear-gradient(
      90deg,
      rgba(132, 235, 155, 0.6) 5.48%,
      rgba(30, 184, 64, 0.6) 96.58%
    ),
    #1eb940;
}

.button:active {
  box-shadow: inset 2px 2px 10px 2px #1eb940;
}

.statistics-bottom {
  background-color: #fff;
  display: none;
}
.statistics-bottom p {
  margin: 0 50px;
  margin-block: 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  height: 100px;
  font-family: "Montserrat", sans-serif;
  color: rgba(0, 194, 89, 1);
  font-weight: 900;
  font-size: 28px;
  line-height: 34.13px;
}

.statistics-bottom p {
  border-top: 1px solid rgba(236, 236, 236, 1);
  border-bottom: 1px solid rgba(236, 236, 236, 1);
}

.statistics-bottom p:nth-child(1),
.statistics-bottom p:nth-child(6) {
  border-top: none;
  border-bottom: none;
}
.statistics-bottom p span {
  margin-top: 6px;
  display: block;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 400;
  color: rgba(57, 57, 57, 1);
}

/* от 1600px */
@media screen and (min-width: 1600px) {
  .wrapper {
    display: block;
    padding: 50px 150px 150px 150px;

    background-size: cover;
  }

  .header {
    margin: 150px 0;
    font-size: 58px;
  }

  .statistics {
    margin-bottom: 100px;
    width: 846px;
  }

  .form {
    width: 846px;
  }

  .statistics p {
    font-size: 36px;
    line-height: 42.13px;
  }

  .statistics p span {
    font-size: 18px;
    line-height: 20.8px;
  }
}

/* от 600px до 800px*/
@media screen and (min-width: 600px) and (max-width: 800px) {
  .wrapper {
    padding: 20px 50px 90px 50px;

    background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.5),
        transparent
      ),
      url(../../assets/creditcentr_mobile_bg.jpg);
    background-position: top;
  }

  .header {
    font-size: 36px;
    font-weight: 900;

    line-height: 39.1px;
    text-shadow: none;
  }

  .phone {
    top: 20px;
    right: 50px;
  }

  .header-block {
    display: flex;
    flex-direction: column;
  }

  .statistics {
    display: none;
  }

  .statistics-bottom {
    display: block;
  }

  .form {
    width: 450px;
    margin: 40px auto 0 auto;

    div {
      margin-top: 20px;
      width: 100%;
      display: block;
    }

    .input {
      width: 100%;
      margin-bottom: 10px;
    }
    .button {
      width: 100%;
    }
  }
}

/* меньше 600px */
@media screen and (max-width: 600px) {
  .wrapper {
    padding: 20px 15px 90px 15px;
    background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.5),
        transparent
      ),
      url(../../assets/creditcentr_mobile_bg.jpg);
  }

  .header-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 50%;
  }

  .form {
    width: 280px;
    margin: 40px auto 0 auto;

    div {
      margin-top: 20px;
      width: 100%;
      display: block;
    }

    .input {
      width: 100%;
      margin-bottom: 10px;
    }
    .button {
      width: 100%;
    }
  }

  .phone {
    position: static;
    margin: 20px auto auto auto;
  }

  .phone-icon {
    margin-right: 10px;
  }

  .phone-disc {
    font-size: 10px;
    line-height: 12.19px;
  }

  .phone-number {
    font-size: 16px;

    line-height: 19.5px;
  }

  .header {
    font-size: 25px;
    font-weight: 900;
    line-height: 28.84px;
    text-align: center;
    text-shadow: none;
  }
  .button-block p {
    color: rgb(73, 73, 73);
  }

  .statistics {
    display: none;
  }

  .statistics-bottom {
    display: block;
  }

  .statistics-bottom p {
    margin: 0 15px;
  }
}

@media screen and (max-width: 400px) {
  .header {
    font-size: 23px;

    line-height: 27.84px;
  }
}
</style>
