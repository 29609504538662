<template>
  <div class="wrapper_quiz preloader">
    <div class="preloader-header">Производится расчет...</div>
    <img src="../../../public/Eclipse-1s-200px.gif" />
  </div>
</template>
<script>
export default {
  name: "pre-loader",
  mounted() {
    setTimeout(() => {
      this.nextPage();
    }, 1000);
  },
  methods: {
    nextPage() {
      this.$emit("nextPage");
    },
  },
};
</script>

<style scoped lang="scss">
.preloader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .preloader-header {
    font-size: 190%;
    font-weight: 700;
    text-align: center;
  }
}
</style>
