<template>
  <div class="wrapper_quiz">
    <b
      >Ваша заявка успешно отправлена, в ближайшее время с вами свяжется наш
      менеджер</b
    >
  </div>
</template>

<script>
export default {
  name: "thank-you-page",
};
</script>

<style scoped></style>
