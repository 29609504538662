<template>
  <div>
    <div class="helpcredit">
      <div class="header">
        <div class="logo">
          <img src="../../assets/logo-helpcredit.svg" alt="логотип" />
        </div>
        <div class="partners">
          <img src="../../assets/partners.svg" alt="логотип" />
        </div>
      </div>
      <div class="content">
        <a href="tel:+375297456442" class="phone">
          <svg
            class="phone-icon"
            width="46"
            height="46"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="46" height="46" rx="23" fill="#EFEFEF" />
            <path
              d="M15.4941 21.7408C17.5844 26.2936 21.3039 29.9132 25.9283 31.8755L26.6695 32.2058C28.3655 32.9614 30.3591 32.3874 31.3935 30.8456L32.3622 29.4017C32.6772 28.9323 32.5813 28.3 32.1413 27.9451L28.8561 25.2947C28.3736 24.9055 27.664 24.9961 27.2949 25.4942L26.2786 26.8656C23.6708 25.5792 21.5536 23.462 20.2672 20.8542L21.6385 19.8379C22.1366 19.4688 22.2273 18.7592 21.838 18.2767L19.1876 14.9914C18.8327 14.5515 18.2006 14.4556 17.7312 14.7703L16.2774 15.7451C14.726 16.7854 14.1551 18.7955 14.9281 20.4959L15.4933 21.739L15.4941 21.7408Z"
              fill="#d4253e"
            />
          </svg>
          <div>
            <p class="phone-disc">Телефон для справки:</p>
            <p class="phone-number">+375 29 <span>745-64-42</span></p>
          </div>
        </a>
        <h1 class="title">ПРОЙДИ ТЕСТ И ПОЛУЧИ КРЕДИТ ОТ БАНКА С 98% ШАНСОМ</h1>
        <h3 class="sub-title">
          На основании ответов мы подберем выгодный кредит из 23 банков Беларуси
          и поможем получить деньги
        </h3>

        <button @click="$emit('someEvent')">НАЧАТЬ ТЕСТ</button>
      </div>
    </div>

    <disclamer siteName="Bank-Credit.by" />
  </div>
</template>

<script>
import disclamer from "@/components/quiz/disclamer.vue";

export default {
  name: "help-credit",
  data() {
    return {
      amount: 146378,
    };
  },
  methods: {
    formatter: function (param) {
      return new Intl.NumberFormat("BY", {
        style: "currency",
        currency: "BYN",
      }).format(param);
    },
  },
  components: {
    disclamer,
  },
};
</script>

<style scoped>
.helpcredit {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
    url("/src/assets/bg.png");
  background-size: cover;
  min-height: 100vh;
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.header .logo img {
  width: 286px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 50px;
}

.phone {
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-bottom: 50px;
}

.phone-icon {
  margin-right: 20px;
}

.phone-disc {
  margin-block: 0;
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 14.63px;
  color: #fff;
}

.phone-number {
  margin-block: 0;
  font-size: 24px;
  font-weight: 400;
  line-height: 29.26px;
  color: #fff;
}

.phone-number span {
  font-weight: 900;
}

@media screen and (max-width: 800px) {
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header .logo {
    margin-bottom: 30px;
  }

  .header .partners img {
    width: 286px;
  }
}
@media screen and (max-width: 600px) {
  .content {
    margin-top: 20px;
  }

  .title {
    font-size: 22px;
  }

  .phone {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-bottom: 20px;
  }
  .phone-icon {
    margin-right: 10px;
  }

  .phone-disc {
    font-size: 10px;
    line-height: 12.19px;
  }

  .phone-number {
    font-size: 16px;

    line-height: 19.5px;
  }

  .sub-title {
    display: none;
  }
}
</style>
