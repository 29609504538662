<template>
  <DefaultPage />
</template>

<script>
import DefaultPage from "./components/DefaultPage.vue";

export default {
  components: {
    DefaultPage,
  },
};
</script>

<style>
body,
html {
  margin: 0;
  padding: 0;
  min-width: 320px;
}
</style>
