<template>
  <div class="wrapper_quiz">
    <p class="page_num">
      Вопрос {{ data.page_id + 1 }} из {{ data.all_pages_count }}
    </p>
    <p class="question">{{ data.question }}</p>
    <p v-if="error" class="error">Выберите один из вариантов!</p>
    <p v-if="minAmountError" class="error">
      Сумма должна быть не менее 500 BYN
    </p>
    <p v-if="amountError" class="error">Введите сумму!</p>
    <div class="answers">
      <!-- <div v-if="getPage()">
        <input
          v-model="count"
          type="number"
          step="1000"
          min="1000"
          class="variant input"
          placeholder="Введите сумму в белорусских рублях"
          @change="setResult(count)"
          v-bind:style="{ borderColor: color }"
        />
      </div> -->
      <div
        v-for="item in data.variants"
        :key="item.id"
        class="variant"
        @click="setResult(item.id)"
        :class="result === item.id ? 'active' : ''"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="buttons-block">
      <button class="next previous" @click="previousPage">Назад</button>
      <!-- <button
        v-if="getPage()"
        class="next"
        @click="result && count >= 500 ? nextPage() : makeError(count)"
        :class="result ? 'active' : ''"
      >
        Далее
      </button> -->
      <button
        class="next"
        @click="result ? nextPage() : Error()"
        :class="result ? 'active' : ''"
      >
        Далее
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data", "initialResult"],
  methods: {
    setResult(id) {
      this.$emit("changeResult", { id, index: this.data.page_id + 1 });
    },
    nextPage() {
      this.$emit("nextPage");
    },

    makeError(count) {
      if (this.getPage()) {
        if (!count) {
          this.amountError = true;
          this.minAmountError = false;
          this.color = "red";
        } else if (count > 0 <= 500) {
          this.amountError = false;
          this.minAmountError = true;
          this.color = "red";
        }
      } else this.error = true;
      this.color = "red";
    },

    Error() {
      this.error = true;
      this.color = "red";
    },

    previousPage() {
      this.$emit("previousPage");
    },

    getPage() {
      if (window.location.pathname.includes("/step-1")) {
        return true;
      } else return false;
    },
  },
  watch: {
    initialResult: function (newVal) {
      this.result = newVal;
    },
  },
  data() {
    return {
      minAmountError: false,
      amountError: false,
      error: false,
      color: "",
      result: this.initialResult,
      count: this.data.variants[0].id,
    };
  },
  name: "interview-form",
};
</script>

<style scoped lang="scss">
.wrapper_quiz .question {
  font-weight: bold;
  font-size: 190%;
  padding: 0;
  margin: 20px 0;
  text-align: center;
}

.wrapper_quiz .page_num {
  padding: 0;
  text-align: center;
}

.error {
  margin-block: 0;
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
}

.wrapper_quiz .answers {
  margin: 15px 0;
  width: 100%;
}

.buttons-block {
  display: flex;
  justify-content: space-between;
}

.wrapper_quiz button {
  background-color: #bcbcbc;
}

.active {
  background: linear-gradient(90deg, #d4253e 0%, #de1f25 100%);
  // background: linear-gradient(90deg, #4625d4, #1f81de);
}

.wrapper_quiz button:hover {
  background: linear-gradient(90deg, #d4253e 0%, #de1f25 100%);
  // background: linear-gradient(90deg, #4625d4, #1f81de);
}

.wrapper_quiz button:first-child {
  margin-right: 20px;
  &::before {
    content: none;
  }
}

.wrapper_quiz .answers .variant {
  background-color: #e7e7e7;
  font-size: 18px;
  /*font-weight: 300;*/
  display: block;
  -webkit-border-radius: 5px;
  color: #000;
  border-radius: 15px;
  padding: 15px 19px;
  margin-bottom: 18px;
  /*text-transform: uppercase;*/
  font-weight: 300;
  cursor: pointer;
}

.input {
  width: 562px;
  width: calc(100% - 38px);
}

.wrapper_quiz .answers .variant.active {
  background: linear-gradient(90deg, #d4253e 0%, #de1f25 100%);
  // background: -webkit-linear-gradient(90deg, #4625d4, #1f81de);
  color: #fff;
}

.wrapper_quiz .answers .variant.active:hover {
  background: linear-gradient(90deg, #d4253e 0%, #de1f25 100%);
  // background: -webkit-linear-gradient(90deg, #4625d4, #1f81de);
  color: #fff;
}

.wrapper_quiz .answers .variant:hover {
  background-color: #d9d9d9;
}
</style>
