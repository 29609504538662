import BaseApi from "./BaseApi";

class Basic extends BaseApi {
  sendLead(data, siteName) {
    let url = '';

    switch (siteName){
      case 'bank-credit.by':
        url = '/add-minsk-lead';
        data.token = "1279t7ehduohwe1udh10d0yge9792";
        break;
      case 'bankhelp.by':
        url = '/add-minsk-lead';
        data.token = "2xwr9egn348f9237fz234fmz023f70";
        break;
      case 'best':
        url = '/add-lead';
        data.token = "bfchysavbckslednicbshjgdkbcakjl";
        break;
      case 'credit-centr.by':
        url = '/add-minsk-lead';
        data.token = "gibrgnfuvuyfdbvnueryfhvbugfyr87t34ref";
        break;
      case 'creditcentr.by':
        url = '/add-minsk-lead';
        data.token = "gyiucbwyouceiuwhednewiuyd72btydghnulmuhox";
        break;
      case 'credit-plus.by':
        url = '/add-minsk-lead';
        data.token = "youwiebndfxwo4hf7cw4fpumw40fmzweof";
        break;
      case 'HelpCredit.by':
        url = '/add-minsk-lead';
        data.token = "fejkbcksnacpewmcnjknbhjfdbvncsd";
        break;
      case 'mtb-credit.by':
        url = '/add-lead';
        data.token = "gyujkbjluygtfudresrwaesycuvibonpui7yt6ury55476ed";
        break;
      case 'privatebank.by':
        url = '/add-minsk-lead';
        data.token = "zgn238fgn23f89dhu9h923ydgnd9d2";
        break;
    }

    return this.axios.post(url, data);
  }
}

export default new Basic();
