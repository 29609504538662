<template>
  <footer>
    <div class="disclamer">
      <p>
        Данный сайт является информационным ресурсом и оказывает помощь в
        получении кредита путем передачи контактных данных посетителя,
        заполнившего форму банкам-партнерам, осуществляющих банковскую
        деятельность на территории Республики Беларусь.
      </p>
      <p>
        Отправляя форму на данном сайте, вы соглашаетесь с правилами обработки
        персональных данных.
      </p>
      <p>
        © ООО "Кредитные системы плюс" (УНП 692205696, адрес: 220069, Минская
        обл., Минский р-н, Новодворский с/с, д. Королищевичи, ул. Центральная,
        д.86, корп.1, оф. 1 .) не является финансовой или микрофинансовой
        организацией, не оказывает услуг по оформлению и предоставлению
        населению микрозаймов, кредитов и других финансовых услуг, регулируемых
        Указом Президента №325 "О привлечении и предоставлении займов,
        деятельности микрофинансовых организаций" от 30.06.2014г. ООО "Кредитные
        системы плюс" (УНП 692205696) оставляет за собой право отправлять заявки
        в зависимости от территориальности и иных критериев в компании-партнеры.
      </p>
      <ul>
        Условия кредитования:
        <li>cумма кредита — от 1000 до 50 000 бел. рублей;</li>
        <li>
          минимальная процентная cтавка 10,5%, максимальная процентная ставка
          25% годовых;
        </li>
        <li>
          минимальный срок кредитования 63 дня, максимальный срок кредитования
          84 мес.
        </li>
      </ul>
      <p>
        К примеру, если Вы возьмете кредит в размере 2000 р. под 12,9% на 24
        месяца, то первый платеж составит 106,17 р. и Ваша итоговая переплата
        составит: 285р. 42 коп.
      </p>
      <p>
        Досрочное погашение без штрафов. При неуплате в срок процентов за
        пользование кредитом, насчитывается пеня в размере 1% от суммы
        неуплаченных процентов за каждый день просрочки.
      </p>
      <p>
        Узнать подробности вы можете по телефону
        <a href="tel:+375297456442">+375 (29) 745-64-42</a>
      </p>
      <p>Компания вправе взымать комиссию за свои услуги.</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "disclamer-footer",
  props: ["siteName"],
};
</script>

<style scoped>
footer {
  background: #fff;
  width: 100%;
  height: 100%;
  padding: 40px 0;
}

.disclamer {
  margin: 0 80px;
  color: black;

  font-size: 10px;
  line-height: 16px;
  font-weight: 300;
}

a {
  text-decoration: none;
  color: inherit;
}

p {
  margin-block: 0;
  margin-bottom: 12px;
}

ul {
  padding: 0;
  margin: 0;
  margin-bottom: 12px;
  list-style-position: inside;
}

@media screen and (max-width: 800px) {
  footer {
    padding: 35px 0 70px 0;
  }
  .disclamer {
    margin: 0 15px;
  }
}
</style>
