<template>
  <div class="wrapper_quiz final">
    <div class="desc">
      <div class="desc__header-block">
        <p class="desc-header">Поздравляем!</p>
        <p class="amount">
          Вы запросили сумму: <span>{{ getAmount(amount) }} BYN</span>
        </p>
      </div>
      <div class="side-block">
        <p>
          <span>Вероятность получения кредита: </span>
          <span class="yellow" v-if="visible">высокая</span>
        </p>
      </div>
      <div class="chance">
        <p>низкая</p>
        <p>средняя</p>
        <p :style="visible ? 'color: black' : ''">высокая</p>
      </div>
      <div class="gradient"></div>
      <div class="side-block">
        <p>
          <span>Подобрано специальных предложений от банков: </span>
          <span class="yellow">{{ int }}</span>
        </p>
      </div>
    </div>
    <div class="form">
      <p class="form-header">Оставьте свои контактные данные:</p>
      <p v-if="nameError" class="error">Введите Ваше имя</p>
      <p v-if="error" class="error">Неверный формат номера телефона!</p>
      <p v-if="codeError" class="error">Неверный код мобильного оператора!</p>
      <input
        class="input"
        v-model="form.name"
        type="text"
        placeholder="Ваше имя"
      />

      <input
        class="input"
        type="text"
        v-model="form.phone"
        v-maska="'+375(##)###-##-##'"
        placeholder="+375(__)___-__-__"
        v-bind:style="{ borderColor: color }"
      />
      <p class="remark_form">
        Нажимая "Получить кредит" вы соглашаетесь с правилами обработки
        персональных данных и подтверждаете, что ознакомились с политикой
        конфеденциальности
      </p>
      <button
        class="next sendForm"
        @click="
          nameValidation(form.name) && phoneNumberValidation(form.phone)
            ? sendForm()
            : makeError()
        "
      >
        Получить кредит
      </button>
    </div>
  </div>
</template>

<script>
import { maska } from "maska";

export default {
  directives: { maska },
  name: "wrapper_quiz_final",
  props: ["initialData"],
  data() {
    return {
      form: {
        phone: "",
        name: "",
      },
      amount: localStorage.getItem("quiz1"),
      visible: false,
      int: null,
      color: "",
      error: false,
      codeError: false,
      nameError: false,
    };
  },
  watch: {
    initialData: function (newVal) {
      this.form = newVal;
    },
  },
  mounted() {
    this.show();
    setTimeout(() => {
      this.getRandomInt(7, 12);
    }, 3000);
  },
  methods: {
    getAmount() {
      if (this.amount === "1") {
        return "До 1000";
      } else if (this.amount === "2") {
        return "От 1000 до 5000";
      } else if (this.amount === "3") {
        return "От 5000 до 15000";
      } else if (this.amount === "4") {
        return "Более 15000";
      } else return this.amount;
    },
    nameValidation(name) {
      if (this.phoneNumberValidation) {
        this.error = false;
        if (name) {
          return true;
        } else this.nameError = true;
        return false;
      }
    },

    getRandomInt(min, max) {
      return (this.int = Math.floor(Math.random() * (max - min + 1)) + min);
    },

    nextPage() {
      this.$emit("nextPage");
    },

    sendForm() {
      this.$emit("sendForm", {
        phone: this.form.phone,
        name: this.form.name,
      });
      console.log(this.name);
    },
    phoneNumberValidation(phone) {
      if (this.nameValidation) {
        this.nameError = false;
        if (phone.length === 17) {
          if (phone.includes("(29)")) {
            return true;
          } else if (phone.includes("(33)")) {
            return true;
          } else if (phone.includes("(44)")) {
            return true;
          } else if (phone.includes("(25)")) {
            return true;
          } else if (phone.includes("(17)")) {
            return true;
          } else this.codeError = true;
          this.error = false;
          this.color = "red";
          return false;
        } else this.error = true;
        this.codeError = false;
        this.color = "red";
        return false;
      }
    },
    show() {
      setTimeout(() => {
        this.visible = true;
      }, 3000);
    },
  },
};
</script>

<style scoped lang="scss">
.final {
  margin-top: 100px;
  max-width: 800px;
  min-height: 350px;
  border-radius: 20px;

  display: flex;
  justify-content: space-between;
}

.error {
  margin-block: 0;
  color: red;
  font-size: 12px;
  margin-bottom: 10px;
}

.desc {
  position: relative;
  width: 60%;
  .desc__header-block {
    display: flex;

    .desc-header {
      font-weight: 700;
      font-size: 190%;
    }

    .amount {
      display: flex;
      flex-direction: column;
    }

    p {
      margin-block: 0;
    }

    p:first-child {
      margin-right: 15px;
    }

    .amount {
      font-size: 12px;
      color: rgb(95, 95, 95);

      span {
        font-size: 170%;
        color: black;
        font-weight: 600;
      }
    }
  }

  .side-block {
    margin-top: 25px;
    margin-left: -25px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 5px 0;

    height: 40px;
    background-color: rgb(232, 232, 232);

    p {
      padding: 25px;
      font-size: 14px;
      font-weight: 600;

      .yellow {
        display: inline-block;
        font-weight: 600;
        padding: 0 2px;
        background-color: rgb(241, 191, 81);
      }
    }
  }

  .chance {
    margin-top: 40px;
    display: flex;
    padding-bottom: 40px;
    width: calc(100% - 25px);
    justify-content: space-between;
    position: relative;
    background-image: linear-gradient(
      to right,
      #333 7%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 16px 1px;
    background-repeat: repeat-x;

    &::after {
      content: "";
      animation-name: ride;
      animation-timing-function: ease;
      animation-duration: 3s;
      animation-fill-mode: forwards;
      position: absolute;
      bottom: -5px;
      right: 95%;
      border: 10px solid transparent;
      border-top: 16px solid rgb(76, 208, 0);
    }

    @keyframes ride {
      25% {
        right: 95%;
      }
      50% {
        right: 30%;
      }
      75% {
        right: 70%;
      }
      100% {
        right: -5px;
      }
    }

    p {
      margin-block: 0;
      font-weight: 600;
      font-size: 12px;
      color: rgb(156, 156, 156);
    }
  }

  .gradient {
    margin-top: 20px;
    width: calc(100% - 25px);
    border-radius: 3px;
    height: 6px;
    background: linear-gradient(
      90deg,
      rgba(255, 0, 0, 1) 0%,
      rgba(251, 255, 0, 1) 50%,
      rgba(0, 255, 6, 1) 100%
    );
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.3) inset;
  }
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px;
  width: calc(40% - 30px);
  border-radius: 20px;
  box-shadow: 2px 2px 13px 2px rgba(0, 0, 0, 0.22);

  &-header {
    font-size: 15px;
    font-weight: 700;
    text-align: center;
  }

  .input {
    padding: 0 10px;
    width: calc(100% - 20px);
    height: 40px;
    margin-bottom: 10px;
    background-color: transparent;
    border: 1px solid gray;
    border-radius: 20px;
  }

  .remark_form {
    color: rgb(121, 121, 121);
    font-size: 10px;
    font-weight: 400;
    margin: 20px 0;
  }

  .sendForm {
    background: linear-gradient(90deg, #4625d4, #1f81de);
    margin-top: 0;
    width: 100%;
    animation-name: zoom;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
  @keyframes zoom {
    25% {
      transform: scale(105%);
    }
    50% {
      transform: scale(95%);
    }
    75% {
      transform: scale(105%);
    }
    100% {
      transform: scale(95%);
    }
  }
}

@media screen and (max-width: 600px) {
  .final {
    display: block;
    margin-top: -10px;

    .desc {
      width: 100%;

      .desc__header-block {
        flex-direction: column;
        align-items: center;

        p:first-child {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }

      .amount {
        align-items: center;
      }

      .side-block {
        margin-top: 15px;
        width: calc(100% + 50px);
        p {
          text-align: center;
          width: 100%;
        }
      }

      .chance {
        margin-top: 20px;
        width: 100%;
        padding-bottom: 30px;
      }
      .gradient {
        width: 100%;
      }
    }

    .form {
      margin-top: 20px;
      width: calc(100% - 50px);

      .input {
        margin-bottom: 10px;
      }

      .remark_form {
        text-align: center;
      }
    }
  }
}
</style>
